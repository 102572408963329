var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { lazy, Suspense } from "react";
import { format, parse, subDays } from "date-fns";
var DatePicker = lazy(function () { return import('react-datepicker'); });
var DateSelection = /** @class */ (function (_super) {
    __extends(DateSelection, _super);
    function DateSelection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            selected: null
        };
        _this.propagateChange = function (date) {
            _this.setState({ selected: date });
            _this.props.form.setFieldValue(_this.props.field.name, format(date, "yyyy-MM-dd"));
        };
        _this.propagateChangeNative = function (event) {
            if (!event.currentTarget.value.length)
                return;
            var dateObj = parse(event.currentTarget.value, 'yyyy-MM-dd', new Date());
            _this.setState({ selected: dateObj });
            _this.props.form.setFieldValue(_this.props.field.name, format(dateObj, "yyyy-MM-dd"));
        };
        _this.handleBlur = function () {
            _this.props.form.setFieldTouched(_this.props.field.name, true);
        };
        _this.resetSelection = function () {
            _this.setState({ selected: null });
        };
        _this.getMobileOperatingSystem = function () {
            var userAgent = navigator.userAgent || navigator.vendor;
            return (/windows phone/i.test(userAgent) ||
                /android/i.test(userAgent) ||
                (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream));
        };
        _this.getDatepicker = function () {
            var isMobile = _this.getMobileOperatingSystem();
            var selectedDate = _this.state.selected !== null ? _this.state.selected : new Date();
            var selectedDateString = format(selectedDate, 'yyyy-MM-dd');
            var maxDate = new Date();
            var maxDateString = format(maxDate, 'yyyy-MM-dd');
            if (isMobile) {
                return (React.createElement("input", { type: "date", value: selectedDateString, className: "form__control", onChange: _this.propagateChangeNative, max: maxDateString }));
            }
            else {
                return (React.createElement(Suspense, { fallback: React.createElement("div", { className: "form__control" }) },
                    React.createElement(DatePicker, { selected: _this.state.selected || subDays(new Date(), 1), onChange: _this.propagateChange, className: "form__control", onBlur: _this.handleBlur, withPortal: true, maxDate: maxDate, placeholderText: "Please enter a date", dateFormat: "dd/MM/yyyy" })));
            }
        };
        _this.render = function () {
            return _this.getDatepicker();
        };
        return _this;
    }
    return DateSelection;
}(React.PureComponent));
export default DateSelection;
